import Box, { BoxProps } from '@mui/joy/Box';
import { Theme as MuiTheme } from '@mui/joy/styles';

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {}
}

export const HotkeySpan = (props: BoxProps) => (
  <Box
    sx={(theme) => ({
      display: 'inline',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.background.level2}`,
      padding: '1px 4px',
      borderRadius: 2,
      fontSize: theme.fontSize.xs,
      fontWeight: theme.fontWeight.sm,
      minWidth: '22px',
      textAlign: 'center',
    })}
    {...props}
  />
);

export interface TooltipHotkeyTitleProps {
  title: string | JSX.Element;
  first?: string;
  second?: string;
}

export const TooltipHotkeyTitle = ({
  title,
  first,
  second,
}: TooltipHotkeyTitleProps) => (
  <>
    {title} <HotkeySpan>{first}</HotkeySpan>{' '}
    <Box
      sx={(theme) => ({
        display: 'inline',
        fontSize: theme.fontSize.xs,
        fontWeight: theme.fontWeight.xs,
      })}
    >
      then
    </Box>{' '}
    <HotkeySpan>{second}</HotkeySpan>
  </>
);
