import {
  StripeConnectInstance,
  loadConnectAndInitialize,
} from '@stripe/connect-js';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import { useEffect, useRef, useState } from 'react';

import {
  useOrganizationId,
  useUser,
} from '@features/Organization/organizationSlice';
import { useMutation } from '@models/mutations/useMutation';

interface StripeConnectProps {
  children: React.ReactNode;
}

const stripeKey = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

export const StripeConnectProvider = ({ children }: StripeConnectProps) => {
  const ref = useRef<boolean>();
  const user = useUser();
  const organizationId = useOrganizationId();

  const [stripeConnectInstance, setStripeConnectInstance] = useState<
    StripeConnectInstance | undefined
  >();

  const [createBillingPortalSessionMutation] = useMutation(
    'createBillingPortalSession'
  );

  useEffect(() => {
    if (ref.current || !user || !organizationId) {
      return;
    }

    ref.current = true;
    (async () => {
      const fetchClientSecret = async () => {
        const result = await createBillingPortalSessionMutation({
          flow: 'fetchAccountSession',
        });

        const clientSecret = result.response?.meta?.clientSecret;

        if (!clientSecret) {
          throw new Error('Client secret not found');
        }

        return clientSecret;
      };

      if (!stripeKey) {
        throw new Error('REACT_APP_PUBLIC_STRIPE_KEY is not set');
      }

      const nextConnectInstance = await loadConnectAndInitialize({
        publishableKey: stripeKey,
        fetchClientSecret: fetchClientSecret,
        appearance: {
          variables: {
            colorPrimary: '#000000',
            spacingUnit: '11px',
          },
        },
      });

      setStripeConnectInstance(nextConnectInstance);
    })();
  }, [user, organizationId, createBillingPortalSessionMutation]);

  return stripeConnectInstance ? (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      {children}
    </ConnectComponentsProvider>
  ) : (
    <>{children}</>
  );
};
