import { Box, CircularProgress } from '@mui/joy';
import { useState } from 'react';

import { useMutation } from '@models/mutations/useMutation';

interface PaymentsListItemProps {
  children?: React.ReactNode;
}

export const PaymentsListItem = ({ children }: PaymentsListItemProps) => {
  const [createBillingPortalSessionMutation] = useMutation(
    'createBillingPortalSession'
  );
  const [loading, setLoading] = useState(false);

  return (
    <Box
      onClick={async () => {
        setLoading(true);
        const { response } = await createBillingPortalSessionMutation({
          flow: 'dashboard',
        });

        setLoading(false);
        const url = response?.meta.url;

        if (!url) {
          throw new Error('URL not found');
        }

        window.open(url, '_blank')?.focus();
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '18px',
          }}
        >
          <CircularProgress size="sm" color="neutral" />
        </Box>
      ) : (
        <>{children}</>
      )}
    </Box>
  );
};
