import { MutationPayload } from '@shared/models/mutations';
import { Actions } from '@shared/models/mutations/sharedRawMutationSchemaBase';
import { useCallback, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useOrganizationId } from '@features/Organization/organizationSlice';
import { auth } from '@utils/firebase';

import { getRunMutation } from './runMutation';

export const useMutation = <T extends Actions>(action: T) => {
  const organizationId = useOrganizationId();
  const [user] = useAuthState(auth);
  const userId = user?.uid;
  const [mutationLoading, setMutationLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const mutateFunction = useCallback(
    async (payload: MutationPayload<T>, userIdOverride?: string) => {
      setMutationLoading(true);

      try {
        const result = await getRunMutation({ action, organizationId, userId })(
          payload,
          userIdOverride
        );
        setError(null);
        setMutationLoading(false);
        return result;
      } catch (nextError) {
        setError(nextError);
        setMutationLoading(false);
        throw nextError;
      }
    },
    [action, organizationId, userId]
  );

  return [mutateFunction, mutationLoading, error] as const;
};
