import { CssVarsProvider, THEME_ID as JOY_THEME_ID } from '@mui/joy/styles';
import { Experimental_CssVarsProvider as MaterialCssVarsProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import theme from '@theme/theme';

import { FeatureFlagsProvider } from './FeatureFlags/FeatureFlagsProvider';
import { HotkeysProvider } from './Hotkeys/HotkeysProvider';
import { StripeConnectProvider } from './StripeConnect';
import { UserDataProvider } from './UserData';

const stripeKey = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

if (!stripeKey) {
  console.log('REACT_APP_PUBLIC_STRIPE_KEY is not set');
}

const stripePromise = loadStripe(stripeKey || '', {
  // TODO: make this dynamic
  stripeAccount: 'acct_1QVwuQPtzFzrIlV6',
});

export interface ProvidersProps {
  children: React.ReactNode;
}

const posthogKey = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

if (posthogKey) {
  posthog.init(posthogKey, {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
  });
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <PostHogProvider client={posthog}>
      <Elements stripe={stripePromise}>
        <CssVarsProvider
          disableTransitionOnChange
          defaultMode="system"
          theme={{ [JOY_THEME_ID]: theme }}
        >
          <MaterialCssVarsProvider defaultMode="system">
            <UserDataProvider>
              <FeatureFlagsProvider>
                <StripeConnectProvider>
                  <HotkeysProvider>{children}</HotkeysProvider>
                </StripeConnectProvider>
              </FeatureFlagsProvider>
            </UserDataProvider>
          </MaterialCssVarsProvider>
        </CssVarsProvider>
      </Elements>
    </PostHogProvider>
  );
};

export default Providers;
