import { IonIcon } from '@ionic/react';
import { List, Typography } from '@mui/joy';
import Box, { BoxProps } from '@mui/joy/Box';
import { AvailableFeatureFlags } from '@shared/models/featureFlags';

import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { SidebarLink } from './SidebarLink';

export interface AppPageList {
  title: string;
  items: AppPage[];
}

export interface AppPage {
  title: string | JSX.Element;
  url?: string;
  iosIcon?: string;
  mdIcon?: string;
  desktopIosIcon?: string;
  desktopMdIcon?: string;
  className?: string;
  selected?: string[];
  featureFlag?: keyof AvailableFeatureFlags;
  exactSelection?: boolean;
  hotkeys?: { first: string; second: string };
  component?:
    | (() => JSX.Element)
    | React.LazyExoticComponent<() => JSX.Element>;
  listComponent?: (props: { children: JSX.Element }) => JSX.Element;
}

export const genericSidebarWidth = 280;

interface GenericSidebarProps {
  lists: AppPageList[];
  children?: React.ReactNode;
  header?: React.ReactNode;
  navSx?: BoxProps['sx'];
}

const GenericSidebar = ({
  lists,
  header,
  children,
  navSx,
}: GenericSidebarProps) => {
  const organizationSlug = useOrganizationSlug();

  return (
    <Box>
      <Box
        sx={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column',
          width: `${genericSidebarWidth}px`,
        }}
      >
        {header}
        <Box
          className="Navigation"
          sx={[
            {
              padding: '30px 16px',
              borderRight: '1px solid',
              borderColor: 'divider',
              border: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            },
            ...(Array.isArray(navSx) ? navSx : [navSx]),
          ]}
        >
          {lists.map(({ title, items }) => (
            <Box key={title}>
              <Typography
                level="body-md"
                fontWeight="800"
                variant="plain"
                fontSize="sm"
                sx={{ marginLeft: '0em' }}
              >
                {title}
              </Typography>
              <List
                aria-labelledby="nav-list-browse"
                size="sm"
                sx={{
                  '& .MuiListItem-root': {
                    margin: 0,
                    padding: 0,
                  },
                  '& .MuiListItemButton-root': {
                    p: '0 1em',
                    margin: '1px 0',
                  },
                  '--ListItem-radius': '6px',
                  '--joy-palette-neutral-solidBg':
                    'var(--joy-palette-neutral-800)',
                }}
              >
                {items.map(
                  ({
                    desktopIosIcon,
                    desktopMdIcon,
                    iosIcon,
                    mdIcon,
                    title: itemTitle,
                    url,
                    selected,
                    exactSelection,
                    hotkeys,
                    listComponent,
                  }) => (
                    <SidebarLink
                      key={url}
                      title={itemTitle}
                      selected={selected}
                      exactSelection={exactSelection}
                      {...hotkeys}
                      href={`/${organizationSlug}${url}`}
                      listComponent={listComponent}
                    >
                      {desktopIosIcon || iosIcon ? (
                        <IonIcon
                          ios={desktopIosIcon || iosIcon}
                          md={desktopMdIcon || mdIcon}
                        />
                      ) : null}
                    </SidebarLink>
                  )
                )}
              </List>
            </Box>
          ))}
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default GenericSidebar;
