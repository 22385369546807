import { OpenInNew } from '@mui/icons-material';
import { Box, Typography } from '@mui/joy';
import {
  ConnectAccountManagement,
  ConnectPayments,
  ConnectPayouts,
} from '@stripe/react-connect-js';
import { lazy, useMemo } from 'react';

import { useFeatureFlags } from '@components/FeatureFlags/useFeatureFlags';
import { AppPageList } from '@components/Sidebar/GenericSidebar';

import { PaymentsListItem } from './PaymentsListItem';

const ProfileSettings = lazy(
  () => import('@features/Settings2/ProfileSettings')
);
const BillingPolicies = lazy(
  () => import('@features/Settings2/BillingPolicies/index')
);
const CancellationPolicies = lazy(
  () => import('@features/Settings2/CancellationPolicies/index')
);
const ServiceSettings = lazy(
  () => import('@features/Settings2/ServiceSettings')
);
const GeneralSettings = lazy(
  () => import('@features/Settings2/GeneralSettings')
);

const settingsAppPageLists: AppPageList[] = [
  {
    title: 'Account',
    items: [
      {
        title: 'View profile',
        url: `/settings/profile`,
        component: ProfileSettings,
      },
      {
        title: 'Notifications',
        url: `/settings/notifications`,
        component: () => <Box>Notifications settings</Box>,
      },
      {
        title: 'Preferences',
        url: `/settings/preferences`,
        component: () => <Box>Preferences settings</Box>,
      },
    ],
  },
  {
    title: 'Scheduling',
    items: [
      {
        title: 'Availability',
        url: `/settings/availability`,
        component: () => <Box>Availability settings</Box>,
      },
      {
        title: 'Waitlist',
        url: `/settings/waitlist`,
        component: () => <Box>Waitlist settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Online booking',
        url: `/settings/online-booking`,
        component: () => <Box>Online booking settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
    ],
  },
  {
    title: 'Billing',
    items: [
      {
        title: 'Billing policies',
        url: `/settings/billing-policies`,
        component: BillingPolicies,
      },
      {
        title: 'Cancellation policies',
        url: `/settings/cancellation-policies`,
        component: CancellationPolicies,
      },
      {
        title: 'Discounts',
        url: `/settings/discounts`,
        component: () => <Box>Discounts settings</Box>,
      },
    ],
  },
  {
    title: 'Payments',
    items: [
      {
        title: 'Transactions',
        url: `/settings/transactions`,
        component: () => (
          <Box sx={{ paddingTop: '10px' }}>
            <ConnectPayments />
          </Box>
        ),
      },
      {
        title: 'Payouts',
        url: `/settings/payouts`,
        component: () => (
          <Box
            sx={{
              paddingTop: '10px',
              width: 'calc(100% + 100px)',
              marginLeft: '-50px',
            }}
          >
            <ConnectPayouts />
          </Box>
        ),
      },
      {
        title: 'Payment settings',
        url: `/settings/payment-account`,
        component: () => (
          <Box sx={{ paddingTop: '10px' }}>
            <ConnectAccountManagement />
            <Box sx={{ marginTop: '50px' }}>
              <PaymentsListItem>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Typography>Stripe Express</Typography>{' '}
                  <OpenInNew sx={{ fontSize: '18px' }} />
                </Box>
              </PaymentsListItem>
            </Box>
          </Box>
        ),
      },
    ],
  },
  {
    title: 'Business',
    items: [
      {
        title: 'Overview',
        url: `/settings`,
        exactSelection: true,
        component: () => <Box>Overview settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'General',
        url: `/settings/general`,
        component: GeneralSettings,
      },
      {
        title: 'Services',
        url: `/settings/services`,
        component: ServiceSettings,
      },
      {
        title: 'Messaging',
        url: `/settings/messaging`,
        component: CancellationPolicies,
      },
      {
        title: 'Workflows',
        url: `/settings/workflows`,
        component: CancellationPolicies,
      },
      {
        title: 'Communications',
        url: `/settings/communications`,
        component: () => <Box>Communications settings</Box>,
        // Not sure if we need this for MVP or post MVP
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Staff',
        url: `/settings/staff`,
        component: () => <Box>Staff settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Locations',
        url: `/settings/locations`,
        component: () => <Box>Locations settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Plans',
        url: `/settings/plans`,
        component: () => <Box>Plans settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Import/export',
        url: `/settings/import-export`,
        component: () => <Box>Import/Export settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
      {
        title: 'Integrations',
        url: `/settings/integrations`,
        component: () => <Box>Integrations settings</Box>,
        featureFlag: 'allSettingsEnabled',
      },
    ],
  },
];

export const useSettingsAppPageLists = (): AppPageList[] => {
  const [featureFlags] = useFeatureFlags();

  return useMemo(
    () =>
      settingsAppPageLists.flatMap((group) => {
        const items = group.items.filter(
          ({ featureFlag }) =>
            featureFlag === undefined || featureFlags[featureFlag]
        );

        return items.length ? { ...group, items } : [];
      }),
    [featureFlags]
  );
};
